// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {}

const _routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'routes',
        name: consts.routerNames.routes.main,
        meta: {
            mainRouteName: consts.routerNames.routes.main,
            list: 'RoutesList',
            showMap: true,
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/prometheus/portal/RoutesPage.vue')
    },
];

export default _routes;