// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {}

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'dashboard-gauges',
        name: consts.routerNames.dashboard_gauges.main,
        meta: {
            mainRouteName: consts.routerNames.dashboard_gauges.main,
            list: 'UnitsList',
            hideMap: true,
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
        },
        // component: () => import('')
    },
];

export default routes;