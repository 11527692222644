// import {objectComparison} from "@/lib/lib";

export default {
    state: {
        statistics: {
            users: [],
            units: [],
        },
        statisticsCalendar: [],
    },
    actions: {
        fetchStatisticsUsers({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsUsers(userId, period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsUsers', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsUnits({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    sub_users: args?.sub_users
                };
                let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsUnits(userId, period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsUnits', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
        fetchStatisticsCalendar({commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                let params = {
                    scale: args?.scale,
                    //sub_users: args?.sub_users
                };
                //let userId = args?.userId || getters.getAppUserId;
                let period = args?.period || 'this_month';

                this.$api.statistics.statisticsCalendar(period, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            // console.log(response.data)
                            commit('setStatisticsCalendar', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        //
                    });
            })
        },
    },
    mutations: {
        setStatisticsUsers(state, data) {
            state.statistics.users = data
        },
        setStatisticsUnits(state, data) {
            state.statistics.units = data
        },

        setStatisticsCalendar(state, data) {
            state.statisticsCalendar = data
        }
    },
    getters: {
        getStatisticsUsers(state) {
            return state.statistics.users
        },
        getStatisticsUnits(state) {
            return state.statistics.units
        },

        getStatisticsCalendar(state) {
            return state.statisticsCalendar
        },
    }
}
